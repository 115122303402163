<template>
  <JenisVariableLaporanManajemenForm mode="Ubah" module="Jenis Variable Laporan Manajemen">
  </JenisVariableLaporanManajemenForm>
</template>

<script>
import JenisVariableLaporanManajemenForm from './form';

const JenisVariableLaporanManajemenUpdate = {
  name: 'JenisVariableLaporanManajemenUpdate',
  components: { JenisVariableLaporanManajemenForm },
};

export default JenisVariableLaporanManajemenUpdate;
</script>
